<template>
<div class="deliver-material" :style="{'padding-bottom':operation==='edit'?'96px':'16px'}">
    <div class="desc">
        <span>{{$t('车辆准备清单')}}</span>
        <span v-if="operation==='edit'&&isCarManager&&['4004001','4004002'].includes(taskStatus)" class="select-all" @click="onSelectAll">{{$t('全选')}}</span>
    </div>
    <div v-if="isDeliveryer&&['4004003','4004004'].includes(taskStatus)" class="oper">
      <span>整备人员: {{ operName }}</span>
      <span>完成时间: {{ operTime }}</span>
    </div>
    <div v-for="key in Object.keys(map)" :key="key">
      <div v-if="map[key]" class="card">
        <div class="common-title">{{ key | codeFormat }}</div>
        <div v-for="c in map[key].children" :key="c.code" class="item">
          <van-checkbox v-model="c.checked" shape="square" :disabled="(isDeliveryer&&!isCarManager) || ['4004003','4004004'].includes(taskStatus)">{{ c.code | codeFormat }}</van-checkbox>
          <div class="remark">{{ c.remark }}</div>
        </div>
      </div>
    </div>
    <div v-if="isDeliveryer&&['4004003','4004004'].includes(taskStatus)" class="card">
        <div class="common-title">{{ $t('验收确认') }}</div>
        <div class="item">
          <van-checkbox v-model="isKeyChecked" shape="square" :disabled="taskStatus==='4004003'">{{ $t('钥匙领取') }}</van-checkbox>
          <div class="remark">{{ $t('请确认车辆整备已符合要求并领取车辆钥匙') }}</div>
        </div>
      </div>
  <div v-if="operation==='edit'" class="common-footer">
    <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
    <van-button v-if="isCarManager&&['4004001','4004002'].includes(taskStatus)" class="btn submit" type="submit" @click="onSubmit">{{ taskStatus==='4004001'?$t('更新'):$t('提交') }}</van-button>
    <van-button v-if="isDeliveryer&&taskStatus==='4004004'" class="btn submit" type="submit" @click="onSubmit">{{ $t('验收确认') }}</van-button>
  </div>
</div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
export default {
  data(){
    return {
      orderId: '',
      taskStatus: '', // 当前任务状态
      params: {
        id: '',
        taskStatus: '4004003',
        executionJson: {
          taskCode: 'DELIVERY_MATERIAL',
          materials: []
        },
      },
      operation: '',
      map: {},
      isCarManager: false,
      isDeliveryer: false,
      isKeyChecked: false,
      operName: '',
      operTime: ''
    }
  },
  computed:{
    ...mapGetters([
      'userInfo'
    ]),
  },
  mounted(){
    // 判断当前员工是车辆管理员 or  交付专员
    // 当前任务状态 是待整备？还是整备完了？
    const { roleLogos=[] } = this.userInfo
    this.isCarManager = roleLogos.includes('1014029') // 车辆管理员
    this.isDeliveryer = roleLogos.includes('1014006') // 交付专员
    console.log(this.isCarManager,'isCarManager')
    console.log(this.isDeliveryer,'isDeliveryer')

    const { taskId,operation,orderId } = this.$route.query
    this.orderId = orderId
    this.params.id = taskId
    this.operation = operation
    // 获取交车单配置
    this.getvehicleReady()
  },
  methods:{
    // 获取车系对应的整备单
    async getvehicleReady(){
      loading.showLoading()
      const res = await deliveryServices.vehicleReady({ orderId: this.orderId })
      loading.hideLoading()
      if (!res.length){
        return this.$toast('厂端未配置对应的准备清单')
      }
      this.dataHandler(res)
      // 反显选中的数据
      // if (this.taskStatus !== '4004002'){
      this.getTaskDetail()
      // }
    },
    // 数据组装
    dataHandler(list=[]){
      const map = {}
      list.forEach(item=>{
        const parentCode = item.parentCode
        const code = item.code 

        if (parentCode&&!map[parentCode]){
          map[parentCode] = {
            name: '',
            children: []
          }
        }
        if (!parentCode&&!map[code]){
          map[code] = {
            name: '',
            children: []
          }
        }

        if (parentCode){
          this.$set(item,'checked',false)
          map[parentCode].children.push(item)
        } else if (!parentCode&&code){
          map[code].name = item.name
        }
      })
      this.$set(this,'map',map)
    },
    // 获取任务详情
    async getTaskDetail() {
      const params = {
        taskId: this.params.id,
      }
      const keys = Object.keys(this.map)
      const res = await deliveryServices.deliveryTaskDetail(params)
      this.taskStatus = res.taskStatus
      if (['4004003','4004004'].includes(this.taskStatus)){
        this.operName = res.executionUserName
        this.operTime = res.executionTime
      }
      this.isKeyChecked = this.taskStatus === '4004003'
      // 回显数据
      res.executionJson?.materials.forEach(item=>{
        keys.forEach(key=>{
          this.map[key].children.forEach(c=>{
            if (item.code === c.code){
              c.checked = item.selected===1
            }
          })
        })
      })
    },
    onSelectAll(){
      const keys = Object.keys(this.map)
      keys.forEach(key=>{
        this.map[key].children.forEach((item,idx)=>{
          this.$set(this.map[key].children[idx], 'checked', true)
        })
      })
    },
    goBack(){
      this.$router.go(-1)
    },
    async onSubmit(){
      const materials = []
      const keys = Object.keys(this.map)
      if (!keys.length){
        return this.$toast('厂端未配置对应的准备清单')
      }
      let isAll = true
      keys.forEach(key=>{
        this.map[key].children.forEach(item=>{
          if (!item.checked) isAll = false
          materials.push({
            ...item,
            selected: item.checked ? 1 : 0,
          })
        })
      })
      this.params.executionJson.materials = materials
      if (!this.isKeyChecked&&this.isDeliveryer&&this.taskStatus==='4004004'){
        return this.$toast('请先检查钥匙')
      }
      // 钥匙验收勾选
      this.params.executionJson.isKeyChecked = this.isKeyChecked
     
      // this.params.taskStatus = isAll ? '4004003' : '4004001'
      // 车辆管理员并且全选 就是待审核 4004004
      // 车辆管理员且非全选  就是未通过 4004001
      // 交付专员且选了钥匙  就是通过 4004003
      this.params.taskStatus = isAll&&this.isCarManager&&['4004001','4004002'].includes(this.taskStatus) ? '4004004' : !isAll&&this.isCarManager ? '4004001' : this.isDeliveryer&&this.isKeyChecked? '4004003': ''
      
      if (this.params.taskStatus==='4004004'){
        // 操作员&时间
        this.params.executionJson.operName = this.userInfo.name
        this.params.executionJson.operTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
      }
      
      console.log(this.params)
      loading.showLoading()
      const res = await deliveryServices.deliveryExecution(this.params)
      loading.hideLoading()
      if (res.code === 0) {
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      } else {
        this.$toast(res.msg)
      }
    }
  }
    
}
</script>
  <style lang="less" scoped>
  .deliver-material{
    padding: 16px;
    background: #fff !important;

    .desc{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .select-all{
        color: #B9921A;
        cursor: pointer;
        user-select: none;
      }
    }
    .oper{
      font-size: 13px;
      color: rgba(13, 23, 26, 0.45);
      margin-bottom: 10px;
      span{
        margin-right: 10px;
      }
    }
  
    .card{
      margin-bottom: 10px;

        .common-title{
            display: flex;
            height: 24px;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-between;
            padding-left: 8px;
            font-size: 16px;
            position: relative;
            &.no-border{
                border: none;
            }
            &::before{
                content: ' ';
                left: 0;
                position: absolute;
                top: calc(50% - 6px);
                width: 2px;
                height: 12px;
                background: #0040C5;
                border-radius: 0px 0px 0px 0px;
            }
        }
      .sub-title{
        padding: 12px 0;
      }
    }
  }
  .item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    /deep/.van-checkbox__label,span{
      height: 16px;
      font-size: 13px;
      font-family: HYQiHei, HYQiHei;
      font-weight: normal;
      color: #0D171A;
      line-height: 16px;
    }
    .remark{
      font-size: 13px;
      color: rgba(13, 23, 26, 0.45);
      margin-left: 28px;
    }
  }
  </style>